.profileTopMenu {
  padding: 5px;
  margin-right: 15px;
  float: right;
  .ant-avatar-lg.ant-avatar-icon {
    background: white;
    color: #868686;
    border-radius: 50%;
  }
}
